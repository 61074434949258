.navbar {
    background-color: var(--main-bg-color) !important;
    color: #0E6A00;
}
.navbar-dark .navbar-brand {
    color: #0E6A00;
}
.navbar-dark .navbar-nav .nav-link {
    color: #0E6A00;
}
.headerLogo-bg{
    max-height: 5vh;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("../images/Hamburger.svg");
}
.customnavlink {
    display: flex;
    justify-content: center;
    gap: 0.25em;
    align-items: center;
}
.customnavlink:hover {
    color: var(--main-green-color) !important;
}
.customnavlink svg {
    transition: all 0.2s ease-in-out;
}
.customnavlink:hover svg {
    transform: rotateZ(45deg);
}
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: var(--main-green-color) !important;
}
.accountButton {
    display: flex;
    justify-content: center;
    align-items: center;
}
.accountButton:hover .nav-link {
    color: white !important;
}
.logoutBtn {
    margin-left: 1em;
}