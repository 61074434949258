.forestBackground {
    border-bottom: 0.125em solid var(--secondary-green-color);
    transform-style: preserve-3d;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 100vh;
}
.coinsBackground {
    background-image: url(/public/coins.png);
    background-blend-mode:color-burn;
    background-position: bottom;
    border-bottom: 0.125em solid var(--secondary-green-color);
}

.tokenBackground{
    position: relative;
    transform-style: preserve-3d;
    width: 100%;
    z-index: 10;
  }
.cardContainer {
    display: flex;
    gap: 3em;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
}
.sectionDescription {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.sectionDescription .descWrapper {
    width: 900px;
    max-width: 80%;
    font-size: 1.25em;
    padding-bottom: 2em;
    text-align: center;
}
.defiSection {
    background-color: var(--secondary-green-color);
    color: var(--main-bg-color);
    height: unset !important;
    position: relative;
    z-index: 100;
}
.defiCard:hover {
    transform: scale(1.1);
}
.defiCard {
    transition: all 0.2s ease-in-out;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 500px;
    max-width: 90%;
    padding: 2em;
    border-radius: 1em;
    border: 2px solid var(--main-bg-color);
    background-color: #135f07;
}
.comingSoon {
    position: absolute;
    top: 5px;
    opacity: 0.5;
    right: 50%;
    transform: translateX(50%);
}
.defiCardButton {
    width: fit-content;
    align-self: center;
    border: 1px solid var(--main-bg-color);
    background-color: unset;
    color: var(--main-bg-color);
}
.defiCardButton.secondary {
    background-color: var(--main-bg-color);
    color: var(--secondary-green-color);
}
.defiCardButtonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
}
/* .blueBackground {
    background-color: #a6ffff;
    color: #375555;
}
.orangeBackground {
    background-color: #fbda8c;
    color: #5a4e31;
} */
.defiCardTitle {
    font-size: 2em;
    font-weight: bold;
}
.defiCardDescription {
    text-align: center;
    font-size: 1.25em;
    padding: 1em 0 1em 0;
}
.moreToolsSoon {
    text-align: center;
    font-size: 1.25em;
    margin-top: 2em;
}
.informationSection {
    background-color: #dbffd6;
    z-index: 100;
    position: relative;
    background-size: contain;
}
.informationSection.nft {
    background-color: var(--secondary-green-color);
    color: #dbffd6;
    z-index: 100;
    position: relative;
}
.wertIntegrationImage {
    max-width: 90%;
    width: 900px;
    margin-bottom: 2em;
}
.partnerLogos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    flex-wrap: wrap;
    max-width: 100% !important;
    width: 100% !important;
}
.partnerLogo {
    height: 40px;
    width: auto;
}

.nftUtilityCards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
    padding-bottom: 2em;
    align-items: flex-start;
}
.nftCard {
    width: 500px;
    max-width: 90%;
    background-color: #135f07a8;
    padding: 2em;
    border-radius: 1em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid var(--main-bg-color);
}
.contactSection {
    background-color: #095100;
    background-image: url("/public/questionMarks.png");
    background-blend-mode:soft-light;
    background-size: contain;
    color: var(--main-bg-color);
    border-top: 2px #052e00 solid;
}
.footerSection {
    background-color: #052e00;
    color: var(--main-bg-color);
    border-top: 2px #052e00 solid;
    padding: 1em;
    margin: 0;
}
.form {
    width: 80%;
}
.emailInput {
    background-color: var(--main-bg-color);
    border: none;
    color: white;
    border-radius: 0;
    border-bottom: 1px solid var(--secondary-green-color);
    text-align: center;
    font-size: 1.25em;
    color: var(--secondary-green-color) !important;
}
.contactEmailInput {
    font-size: 1em;
    border-radius: 1em;
}
.textArea {
    height: 300px;
    background-color: var(--main-bg-color);
    outline: none;
    border: none;
    border-radius: 1em;
    color: var(--secondary-green-color);
}
.textArea:focus {
    background-color: var(--main-bg-color);
    color: var(--secondary-green-color);
}
.emailInput:focus {
    background-color: var(--main-bg-color);
    color: var(--secondary-green-color);
    box-shadow: none !important;
    outline: none !important;
    border-bottom: 1px solid var(--secondary-blue-color);
}
label {
    color: var(--secondary-green-color);
}
.nftBackground {
    background-image: url(/public/tokenBackground.png);
    background-blend-mode:color-burn;
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: cover;
}
.form-floating>label {
    left: 50%;
    transform: translateX(-50%);
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    transform: scale(.85) translateY(-1rem) translateX(-50%);
}

.footerIcon {
    transition: all 0.2s ease-in-out;
    color: var(--main-bg-color) !important;
}
.footerIcon:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: var(--main-bg-color) !important;
}
.spinnerIcon {
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation:spin 0.5s linear infinite;
    animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }