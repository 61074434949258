
.mainPageTextCard {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40em;
    text-align: center;
    max-width: 80%;
    transform-style: preserve-3d;
    z-index: -1;
    }
  .titlePrimary {
    font-size: 1.5em;
  }
  .titleSecondary {
    margin-bottom: 0.75em;
    font-size: 3em;
    text-align: center;
  }
  .description {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    width: 100%;
    padding-top: 2em;
  }
  .buttons2 {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    width: 100%;
    padding-top: 0.5em;
  }
  .title {
    line-height: 2em;
    padding-bottom: 2em;
  }
  @media screen and (min-width: 520px) {
    .buttons {
      flex-direction: row;
    }
    .buttons2 {
      flex-direction: row;
    }
  }
  