:root {
  --main-bg-color: #B7FFAC;
  --main-green-color: #1BC900;
  --secondary-green-color: #0E6A00;
  --secondary-green-color-darker: #094100;
  --main-blue-color: #80FFFF;
  --secondary-blue-color: #005660;
}



.App {
  text-align: center;
  font-size: calc(8px + 1vmin);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.earth-bg {
  max-width: 100vw;
  position: fixed;
  left: 50%;
  transform: translateX(-51.7%);
  bottom: -10%;
  z-index: -98;
  opacity: 1;
}

.grass-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  z-index: 100;

}

.leafs-bg {
  position: fixed;
  bottom: -15%;
  left: -1%;
  max-width: 110vh;
  max-height: 110vh;
  z-index: -99;
  opacity: 0.5;
}

.leafs-bg2 {
  position: fixed;
  bottom: -15%;
  right: -1%;
  max-width: 110vh;
  max-height: 110vh;
  z-index: -99;
  opacity: 0.5;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  perspective: 10px;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  z-index: 0;
}


.contentContainer {
  z-index: 10;
  position: relative;
  padding-top: 5em;
  padding-bottom: 5em;
  color: var(--secondary-green-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.inverted{
  color: var(--main-bg-color);
  background-color: var(--secondary-green-color);
}
.wip {
  position: relative;
}

.wip::after {
  width: fit-content;
  content: "coming soon";
  position: absolute;
  bottom: -3px;
  right: -3px;
  padding: 0.25em 1em;
  border-radius: 100px;
  background-color: var(--secondary-green-color);
  font-size: 0.5em;
  color: white;
}
.wip.demo::after {
  content: "demo";
}

.wip::after:hover {
  color: white;
}


.btn {
  padding: 10px 15px;
  border-radius: 100px;
  color: var(--secondary-blue-color);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.75em;
  gap: 1em;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  background-color: var(--secondary-blue-color);
  color: white;
  border: .1em solid var(--secondary-blue-color);
}

.btn-primary:hover {
  background-color: var(--secondary-blue-color);
  border-color: var(--secondary-blue-color);
  color: white;
  transform: scale(1.1);
}

.btn-secondary {
  background-color: var(--main-bg-color);
  border: .1em solid var(--secondary-green-color);
  color: var(--secondary-green-color);
}

.btn-secondary:hover {
  background-color: var(--main-bg-color);
  border-color: var(--secondary-green-color);
  transform: scale(1.1);
  color: var(--secondary-green-color);
}
.btn-secondary:focus {
  background-color: var(--secondary-green-color);
  border-color: var(--secondary-green-color);
  color: white;
  transform: scale(1.1);
}

.btn-primary:hover svg,.btn-secondary:hover svg {
  transform: rotateZ(45deg);
}
.btn-primary svg,.btn-secondary svg {
  transition: all 0.2s ease-in-out;
} 
.scrollDown{
  font-weight: bold;
  position: absolute;
  bottom: 5px;
}
.logoIcon-bg {
  height: 20px;
}
.background {
  transform: translateZ(-10px) scale(2);
}
.background {
  position: absolute;
  background-image: url(/public/g8.png);
  background-position: bottom;
  background-repeat: repeat-x;
  height: 100%;
  width: 100%;
  object-fit: cover;
  bottom: 0;
  opacity: 0.15;
  transform-style: preserve-3d;
  z-index: -2;
}
.tokenBackground .background {
  transform: translateZ(-1px) scale(1.1);
  z-index: -3;
}
.tokenBackground .background {
  background-color: var(--main-bg-color);
  background-image: url(/public/tokenBackground.png);
  background-position: bottom;
  opacity: 1;
  z-index: -1;
  background-blend-mode: darken;
  min-height: 100vh;
}
.roaringTigers {
  width: 100vw;
  max-width: 2000px;
  min-height: 20em;
  background-image: url(/src/images/roaring-tigers.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}
@media screen and (min-width: 900px) {
  .earth-bg {
    max-width: 80vw;
  }
}
@media screen and (min-width: 700px) {
  .earth-bg {
    max-width: 75vw;
  }
}

@media screen and (min-width: 1000px) {
  .earth-bg {
    max-width: 45vw;
  }

  .contentContainer {
    flex-direction: column;
  }

  .earth-bg {
    right: 0;
    bottom: -5%;
    transform: none;
    max-width: 100vh;
  }

  .leafs-bg {
    opacity: 1;
  }
}
.loadingScreen {
  background-color: var(--main-bg-color);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top:-200%;
  z-index: 200;
  transition: all 0.5s ease-in-out;
}
.loadingScreen.loading {
  top: 0;
}
.loadingScreen.loading .logo-loading {
  transform: rotateZ(720deg);
  -webkit-transform: rotateZ(720deg);
}
.loadingScreen.loading .logo-loading {
  transition: all 2s ease-in-out;
}
.logo-loading {
  width: 20vw;
}


@media screen and (max-width: 853px) {
  .video-responsive {
    padding-bottom: 56.25%;
    position: relative;
    background-color: black;
    height: 0;
    max-height: 480;
    z-index: 10;
    color: var(--secondary-green-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  .video-responsive iframe {
    z-index: 2000;
    left: 50;
    top: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
  }
}

.video-responsive {
  background-color: black;
  z-index: 10;
  width: 100vw;
}

